import React from "react";

const Navdata = () => {
  const menuItems = [
    {
      label: "Menu",
      isHeader: true,
      for_superuser: false,
    },
    {
      id: "projects",
      label: "Проекты",
      icon: "ri-stack-line",
      link: "/projects",
      for_superuser: true,
      subItems: [],
    },
    {
      id: "groups",
      label: "Группы",
      icon: "ri-group-2-line",
      link: "/groups",
      for_superuser: false,
      subItems: [],
    },
    {
      id: "news",
      label: "Новости",
      icon: "ri-newspaper-line",
      link: "/news",
      for_superuser: false,
      subItems: [],
    },
    {
      id: "users",
      label: "Пользователи",
      icon: "ri-group-line",
      link: "/users",
      for_superuser: true,
      subItems: [],
    },
    {
      id: "logs",
      label: "Логи",
      icon: "ri-file-list-2-line",
      link: "/logs",
      for_superuser: false,
      subItems: [],
    },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
