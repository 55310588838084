import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "..";
import {
  CreateNewsGroupFormType,
  NewsGroupStatusType,
  NewsGroupType,
} from "../../types/groups";
import {
  STORAGE_KEY_FILTER_GROUP_NEWS_COUNT,
  STORAGE_KEY_FILTER_GROUP_SEARCH,
  STORAGE_KEY_FILTER_GROUP_STATUS,
} from "../../features/constants";
import { OptionType } from "../../types/common";

interface GroupsProcessingState {
  isOpenModal: boolean;
  currentGroup: NewsGroupType | null;
  createNewsForm: CreateNewsGroupFormType;
  status: OptionType<string, NewsGroupStatusType | string> | undefined;
  search: string;
  news_count: number | undefined;
}

const initialState: GroupsProcessingState = {
  isOpenModal: false,
  currentGroup: null,
  createNewsForm: {
    participants: "",
    picture: "",
    tags: [],
    text: "",
  },
  status: JSON.parse(
    localStorage.getItem(STORAGE_KEY_FILTER_GROUP_STATUS)!
  ) || {
    label: "новый",
    value: "new",
  },
  search: localStorage.getItem(STORAGE_KEY_FILTER_GROUP_SEARCH) || "",
  news_count:
    Number(localStorage.getItem(STORAGE_KEY_FILTER_GROUP_NEWS_COUNT)) ||
    undefined,
};

export const groupsProcessingSlice = createSlice({
  name: "groups-processing",
  initialState,
  reducers: {
    setIsOpenModalProcessing: (state, action: PayloadAction<boolean>) => {
      state.isOpenModal = action.payload;
    },
    setCurrentProcessingGroup: (
      state,
      action: PayloadAction<NewsGroupType | null>
    ) => {
      state.currentGroup = action.payload;
    },
    setGroupFilterStatus: (
      state,
      action: PayloadAction<
        OptionType<string, NewsGroupStatusType | string> | undefined
      >
    ) => {
      state.status = action.payload;
    },
    setGroupFilterSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setGroupFilterNewsCount: (
      state,
      action: PayloadAction<number | undefined>
    ) => {
      state.news_count = action.payload;
    },
  },
});

export const groupsProcessingActions = groupsProcessingSlice.actions;

export const selectGroupsProcessing = (state: RootState) => state.processing;

export default groupsProcessingSlice.reducer;
