import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";

import LayoutReducer from "./layouts/reducer";

import LoginReducer from "./auth/login/reducer";
import AccountReducer from "./auth/register/reducer";
import ForgetPasswordReducer from "./auth/forgetpwd/reducer";
import ProfileReducer from "./auth/profile/reducer";

import AuthSlice from "./new/auth";
import ProjectSlice from "./new/project";
import GroupsProcessingSlice from "./new/group-processing";
import NewsSlice from "./new/news";
import UsersSlice from "./new/users";
import RSSSlice from "./new/rss";
import TGSlice from "./new/tg";
import TagSlice from "./new/tag";
import ProxySlice from "./new/proxy";
import GroupForm from "./new/group-form";

const rootReducer = combineReducers({
  Layout: LayoutReducer,
  Login: LoginReducer,
  Account: AccountReducer,
  ForgetPassword: ForgetPasswordReducer,
  Profile: ProfileReducer,
  auth: AuthSlice,
  project: ProjectSlice,
  processing: GroupsProcessingSlice,
  users: UsersSlice,
  news: NewsSlice,
  rss: RSSSlice,
  tg: TGSlice,
  tag: TagSlice,
  proxy: ProxySlice,
  groupForm: GroupForm,
});

export const store = configureStore({ reducer: rootReducer });

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default rootReducer;
