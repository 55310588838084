module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  cookie: {
    ACCESS_KEY: "gpt_news_access_token",
    REFRESH_KEY: "gpt_news_refresh_token",
  },
  api: {
    API_URL: "https://api.chatgpt-news.apptrix.ru/api",
  }
};
